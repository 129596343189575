import { getEmployeeAppraisalFilterAppraisal1 } from "./action";
import {
  GET_BD_USER,
  GET_DEPARTMENT,
  GET_PROJECT_LIST,
  ADD_TASK,
  GET_TASK,
  GET_TASK_CATEGORY,
  GET_TASK_EXPORT,
  GET_FEASIBILITY_REPORT_DETAIL,
  UPDATE_TASK_OWNER,
  UPDATE_TASK_START,
  UPDATE_TASK_COMPLETED,
  GET_TASK_DETAIL,
  GET_TASK_PROJECT_FILTER,
  GET_TASK_CATEGORY_FILTER,
  GET_TASK_DEPARTMENT_FILTER,
  GET_TASK_OWNER_FILTER,
  GET_USER_DESIGNATION,
  GET_USER_ROLE_FUNCTIONALITY,
  CREATE_USER_ROLE_FUNCTIONALITY,
  GET_PUBLIC_HOLIDAY_LIST,
  GET_YEAR,
  GET_SALARY_ADVANCE_DEDUCTION_HISTORY,
  GET_SALARY_ADVANCE_EXPORT,
  GET_SALARY_ADVANCE,
  GET_OUT_PASS,
  GET_EMPLOYEE,
  GET_OUT_PASS_EXPORT,
  GET_EMPLOYEE_ATTENDANCE,
  GET_LEAVE_TYPE,
  GET_EMPLOYEE_CHECKIN,
  GET_PETTY_CASH,
  GET_PETTY_CASH_MASTER_EXPORT,
  GET_LEAVE_SUMMARY,
  GET_COMP_OFF_LEAVE_SUMMARY,
  GET_VEHICLE_SERVICE_REQUEST,
  GET_VECHICLE_TYPE,
  GET_SERVICE_TYPE,
  GET_EMPLOYEE_ATTENDANCE_EXPORT,
  GET_VEHICLE_SERVICE_REQUEST_EXPORT,
  GET_VEHICLE_NUMBER,
  GET_GST,
  GET_ASSEST_TYPE,
  GET_DESIGNATION,
  GET_DESIGNATION_EXPORT,
  GET_DEPARTMENT_MASTER,
  GET_DEPARTMENT_EXPORT,
  GET_LEVEL,
  GET_LEVEL_EXPORT,
  GET_JOB_ROLE,
  GET_DEPARTMENT_DESIGNATION,
  GET_TASK_ACCOUNTABLE_FILTER,
  GET_APPRAISAL_QUESTION_EXPORT,
  GET_APPRAISAL_QUESTION_OPTION,
  GET_APPRAISAL_QUESTION_OPTION_EXPORT,
  GET_EMPLOYEE_APPRAISAL_QUESTION,
  GET_EMPLOYEE_APPRAISAL,
  GET_EMPLOYEE_APPRAISAL_EXPORT,
  GET_APPRAISAL_QUESTION,
  GET_APPRAISAL,
  GET_APPRAISAL_CATEGORY,
  GET_APPRAISAL_RATING,
  GET_QUESTION,
  GET_QUESTION_VALUE,
  GET_QUESTION_VALUE_SET,
  GET_APPRAISAL_QUESTION_PRINT,
  GET_HR_LEAVE_TYPE,
  GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT,
  GET_TO_DO_CATEGORY,
  GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE,
  GET_HR_APPRAISAL_QUESTION_DESIGNATION,
  GET_APPRAISAL_RATING_EXPORT,
  GET_APPRAISAL_QUESTION_CATEGORY_FILTER,
  GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER,
  GET_APPRAISAL_QUESTION_DESIGNATION_FILTER,
  GET_HR_APPRAISAL_QUESTION_OPTION,
  GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL,
  GET_EMPLOYEE1,
  GET_EMPLOYEE_FOR_APPRAISAL,
  GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE,
  GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL,
  GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL1,
  GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL,
  GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT,
  GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION,
  GET_EMPLOYEE_APPRAISAL_DETAIL,
  GET_PENDING_TODO,
  GET_USER_ROLE_FUNCTIONALITY_DETAIL,
  GET_USER_ROLE_FUNCTIONALITY_FILTER_DEPARTMENT,
  GET_USER_ROLE_FUNCTIONALITY_FILTER_DESIGNATION,
} from "./types";
const initialStateChart = {
  labels: [],
  datasets: [],
};
const initialState = {
  projectAuthority: [],
  // civilResourceTypePlanning : initialStateChart,
  // civilBudgetPlanning : initialStateChart,
  user: [],
  projectList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  departmentList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  feasibilityReportDetail: [],
  createTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateTaskOwner: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateTaskStart: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateTaskComplete: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskCategoryFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskDepartmentFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskOwnerFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getUserDesignation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getUserRoleFunctionality: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  createUserRoleFunctionality: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPublicHolidayList: {
    error: false,
    message: "",
    data: [],
  },
  getYear: {
    error: false,
    message: "",
    data: [],
  },
  getSalaryAdvanceMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalaryAdvanceMasterExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalaryAdvanceDeductionHistory: {
    error: false,
    message: "",
    data: [],
  },
  getOutPass: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployee: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOutPassExport: {
    error: false,
    message: "",
    data: [],
  },
  getEmployeeAttendance: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLeaveType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeCheckin: {
    error: false,
    message: "",
    data: [],
  },
  getPettyCashMaster: {
    error: false,
    message: "",
    data: [],
  },
  getPettyCashMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getLeaveSummary: {
    error: false,
    message: "",
    data: [],
  },
  getCompOffLeaveSummary: {
    error: false,
    message: "",
    data: [],
  },
  getVehicleServiceRequest: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVehicleType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getServiceType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAttendanceExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVehicleServiceRequestExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVehicleNumber: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGst: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAssetType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDesignation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDesignationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDepartmentMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDepartmentExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLevelExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getJobRole: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLevel: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDepartmentDesignation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskAccountableFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestion: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionOption: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionOptionExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalQuestion: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalRating: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFeedbackQuestion: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getFeedbackQuestionOptionValue: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getFeedbackQuestionOptionValueSet: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionPrint: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getHrLeaveType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalQuestionPrint: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getToDoCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getHrAppraisalQuestionDesignationForUpdate: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getHrAppraisalQuestionDesignation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalRatingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionCategoryFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionDepartmentFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAppraisalQuestionDesignationFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getHrAppraisalQuestionOption: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getHrAppraisalQuestionOptionDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeForAppraisal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalFilterEmployee: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalFilterAppraisal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeDepartmentForAppraisal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalFilterDepartment: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalFilterDesgination: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEmployeeAppraisalDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPendingToDo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getUserRoleFunctionalityDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getUserRoleFunctionalityFilterDesignation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getUserRoleFunctionalityFilterDepartment: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};
const HR_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BD_USER:
      return Object.assign({}, state, { user: payload });
    case GET_DEPARTMENT:
      return Object.assign({}, state, { departmentList: payload });
    case GET_PROJECT_LIST:
      return Object.assign({}, state, { projectList: payload });
    case ADD_TASK:
      return Object.assign({}, state, { createTask: payload });
    case GET_TASK:
      return Object.assign({}, state, { getTask: payload });
    case GET_TASK_CATEGORY:
      return Object.assign({}, state, { getTaskCategory: payload });
    case GET_TASK_EXPORT:
      return Object.assign({}, state, { getTaskExport: payload });
    case GET_FEASIBILITY_REPORT_DETAIL:
      return Object.assign({}, state, { feasibilityReportDetail: payload });
    case UPDATE_TASK_OWNER:
      return Object.assign({}, state, { updateTaskOwner: payload });
    case UPDATE_TASK_START:
      return Object.assign({}, state, { updateTaskStart: payload });
    case UPDATE_TASK_COMPLETED:
      return Object.assign({}, state, { updateTaskComplete: payload });
    case GET_TASK_DETAIL:
      return Object.assign({}, state, { getTaskDetail: payload });
    case GET_TASK_PROJECT_FILTER:
      return Object.assign({}, state, { getTaskProjectFilter: payload });
    case GET_TASK_CATEGORY_FILTER:
      return Object.assign({}, state, { getTaskCategoryFilter: payload });
    case GET_TASK_DEPARTMENT_FILTER:
      return Object.assign({}, state, { getTaskDepartmentFilter: payload });
    case GET_TASK_OWNER_FILTER:
      return Object.assign({}, state, { getTaskOwnerFilter: payload });
    case GET_USER_DESIGNATION:
      return Object.assign({}, state, { getUserDesignation: payload });
    case GET_USER_ROLE_FUNCTIONALITY:
      return Object.assign({}, state, { getUserRoleFunctionality: payload });
    case CREATE_USER_ROLE_FUNCTIONALITY:
      return Object.assign({}, state, { createUserRoleFunctionality: payload });
    case GET_PUBLIC_HOLIDAY_LIST:
      return Object.assign({}, state, { getPublicHolidayList: payload });
    case GET_YEAR:
      return Object.assign({}, state, { getYear: payload });
    case GET_SALARY_ADVANCE:
      return Object.assign({}, state, { getSalaryAdvanceMaster: payload });
    case GET_SALARY_ADVANCE_DEDUCTION_HISTORY:
      return Object.assign({}, state, {
        getSalaryAdvanceDeductionHistory: payload,
      });
    case GET_SALARY_ADVANCE_EXPORT:
      return Object.assign({}, state, {
        getSalaryAdvanceMasterExport: payload,
      });
    case GET_OUT_PASS:
      return Object.assign({}, state, { getOutPass: payload });
    case GET_EMPLOYEE:
      return Object.assign({}, state, { getEmployee: payload });
    case GET_OUT_PASS_EXPORT:
      return Object.assign({}, state, { getOutPassExport: payload });
    case GET_EMPLOYEE_ATTENDANCE:
      return Object.assign({}, state, { getEmployeeAttendance: payload });
    case GET_LEAVE_TYPE:
      return Object.assign({}, state, { getLeaveType: payload });
    case GET_EMPLOYEE_CHECKIN:
      return Object.assign({}, state, { getEmployeeCheckin: payload });
    case GET_PETTY_CASH:
      return Object.assign({}, state, { getPettyCashMaster: payload });
    case GET_PETTY_CASH_MASTER_EXPORT:
      return Object.assign({}, state, { getPettyCashMasterExport: payload });
    case GET_LEAVE_SUMMARY:
      return Object.assign({}, state, { getLeaveSummary: payload });
    case GET_COMP_OFF_LEAVE_SUMMARY:
      return Object.assign({}, state, { getCompOffLeaveSummary: payload });
    case GET_VEHICLE_SERVICE_REQUEST:
      return Object.assign({}, state, { getVehicleServiceRequest: payload });
    case GET_VECHICLE_TYPE:
      return Object.assign({}, state, { getVehicleType: payload });
    case GET_SERVICE_TYPE:
      return Object.assign({}, state, { getServiceType: payload });
    case GET_EMPLOYEE_ATTENDANCE_EXPORT:
      return Object.assign({}, state, { getEmployeeAttendanceExport: payload });
    case GET_VEHICLE_SERVICE_REQUEST_EXPORT:
      return Object.assign({}, state, {
        getVehicleServiceRequestExport: payload,
      });
    case GET_VEHICLE_NUMBER:
      return Object.assign({}, state, { getVehicleNumber: payload });
    case GET_GST:
      return Object.assign({}, state, { getGst: payload });
    case GET_ASSEST_TYPE:
      return Object.assign({}, state, { getAssetType: payload });
    case GET_DESIGNATION:
      return Object.assign({}, state, { getDesignation: payload });
    case GET_DESIGNATION_EXPORT:
      return Object.assign({}, state, { getDesignationExport: payload });
    case GET_DEPARTMENT_MASTER:
      return Object.assign({}, state, { getDepartmentMaster: payload });
    case GET_DEPARTMENT_EXPORT:
      return Object.assign({}, state, { getDepartmentExport: payload });
    case GET_LEVEL:
      return Object.assign({}, state, { getLevel: payload });
    case GET_LEVEL_EXPORT:
      return Object.assign({}, state, { getLevelExport: payload });
    case GET_JOB_ROLE:
      return Object.assign({}, state, { getJobRole: payload });
    case GET_DEPARTMENT_DESIGNATION:
      return Object.assign({}, state, { getDepartmentDesignation: payload });
    case GET_TASK_ACCOUNTABLE_FILTER:
      return Object.assign({}, state, { getTaskAccountableFilter: payload });
    case GET_APPRAISAL_QUESTION:
      return Object.assign({}, state, { getAppraisalQuestion: payload });
    case GET_APPRAISAL_QUESTION_EXPORT:
      return Object.assign({}, state, { getAppraisalQuestionExport: payload });
    case GET_APPRAISAL_QUESTION_OPTION:
      return Object.assign({}, state, { getAppraisalQuestionOption: payload });
    case GET_APPRAISAL_QUESTION_OPTION_EXPORT:
      return Object.assign({}, state, {
        getAppraisalQuestionOptionExport: payload,
      });
    case GET_EMPLOYEE_APPRAISAL_QUESTION:
      return Object.assign({}, state, {
        getEmployeeAppraisalQuestion: payload,
      });
    case GET_EMPLOYEE_APPRAISAL:
      return Object.assign({}, state, { getEmployeeAppraisal: payload });
    case GET_EMPLOYEE_APPRAISAL_EXPORT:
      return Object.assign({}, state, { getEmployeeAppraisalExport: payload });
    case GET_APPRAISAL:
      return Object.assign({}, state, { getAppraisal: payload });
    case GET_APPRAISAL_CATEGORY:
      return Object.assign({}, state, { getAppraisalCategory: payload });
    case GET_APPRAISAL_RATING:
      return Object.assign({}, state, { getAppraisalRating: payload });
    case GET_QUESTION:
      return Object.assign({}, state, { getFeedbackQuestion: payload });
    case GET_QUESTION_VALUE:
      return Object.assign({}, state, {
        getFeedbackQuestionOptionValue: payload,
      });
    case GET_QUESTION_VALUE_SET:
      return Object.assign({}, state, {
        getFeedbackQuestionOptionValueSet: payload,
      });
    case GET_APPRAISAL_QUESTION_PRINT:
      return Object.assign({}, state, { getAppraisalQuestionPrint: payload });
    case GET_HR_LEAVE_TYPE:
      return Object.assign({}, state, { getHrLeaveType: payload });
    case GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT:
      return Object.assign({}, state, {
        getEmployeeAppraisalQuestionPrint: payload,
      });
    case GET_TO_DO_CATEGORY:
      return Object.assign({}, state, { getToDoCategory: payload });
    case GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE:
      return Object.assign({}, state, {
        getHrAppraisalQuestionDesignationForUpdate: payload,
      });
    case GET_HR_APPRAISAL_QUESTION_DESIGNATION:
      return Object.assign({}, state, {
        getHrAppraisalQuestionDesignation: payload,
      });
    case GET_APPRAISAL_RATING_EXPORT:
      return Object.assign({}, state, { getAppraisalRatingExport: payload });
    case GET_APPRAISAL_QUESTION_CATEGORY_FILTER:
      return Object.assign({}, state, {
        getAppraisalQuestionCategoryFilter: payload,
      });
    case GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER:
      return Object.assign({}, state, {
        getAppraisalQuestionDepartmentFilter: payload,
      });
    case GET_APPRAISAL_QUESTION_DESIGNATION_FILTER:
      return Object.assign({}, state, {
        getAppraisalQuestionDesignationFilter: payload,
      });
    case GET_HR_APPRAISAL_QUESTION_OPTION:
      return Object.assign({}, state, {
        getHrAppraisalQuestionOption: payload,
      });
    case GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL:
      return Object.assign({}, state, {
        getHrAppraisalQuestionOptionDetail: payload,
      });
    case GET_EMPLOYEE_FOR_APPRAISAL:
      return Object.assign({}, state, { getEmployeeForAppraisal: payload });
    case GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE:
      return Object.assign({}, state, {
        getEmployeeAppraisalFilterEmployee: payload,
      });
    case GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL:
      return Object.assign({}, state, {
        getEmployeeAppraisalFilterAppraisal: payload,
      });
    case GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL:
      return Object.assign({}, state, {
        getEmployeeDepartmentForAppraisal: payload,
      });
    case GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT:
      return Object.assign({}, state, {
        getEmployeeAppraisalFilterDepartment: payload,
      });
    case GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION:
      return Object.assign({}, state, {
        getEmployeeAppraisalFilterDesgination: payload,
      });

    case GET_EMPLOYEE_APPRAISAL_DETAIL:
      return Object.assign({}, state, { getEmployeeAppraisalDetail: payload });
    case GET_PENDING_TODO:
      return Object.assign({}, state, { getPendingToDo: payload });
    case GET_USER_ROLE_FUNCTIONALITY_DETAIL:
      return Object.assign({}, state, {
        getUserRoleFunctionalityDetail: payload,
      });
    case GET_USER_ROLE_FUNCTIONALITY_FILTER_DESIGNATION:
      return Object.assign({}, state, {
        getUserRoleFunctionalityFilterDesignation: payload,
      });
    case GET_USER_ROLE_FUNCTIONALITY_FILTER_DEPARTMENT:
      return Object.assign({}, state, {
        getUserRoleFunctionalityFilterDepartment: payload,
      });
    default:
      return state;
  }
};
export default HR_reducer;
