import React, { useState } from 'react';
import ResponsiveAppBar from './AppBar';
import { styled, useTheme } from '@mui/material/styles';
import { Drawer as MuiDrawer, List, Divider, ListItemButton, Collapse } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { dateFormat } from '../../utils/date';
import { useDispatch, useSelector } from "react-redux";
import { getUserMenuItem_action } from '../../Redux/User/action';
import { getItem } from '../../utils/crypto';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const DashboardIcon = React.lazy(()=> import("@mui/icons-material/Home"))
const CivilIcon = React.lazy(()=> import("@mui/icons-material/Engineering"))
const LiaisonIcon = React.lazy(()=> import("@mui/icons-material/Policy"))
const BDIcon = React.lazy(()=> import("@mui/icons-material/LocationCity"))
const LegalIcon = React.lazy(()=> import("@mui/icons-material/Gavel"))
const AdminIcon = React.lazy(()=> import("@mui/icons-material/AdminPanelSettings"))
const TrainingIcon = React.lazy(()=> import("@mui/icons-material/ModelTraining"))
const AccountIcon = React.lazy(()=> import("@mui/icons-material/ManageAccounts"))
const ReportIcon = React.lazy(()=> import("@mui/icons-material/Summarize"))
const menuIcon = React.lazy(()=> import("@mui/icons-material/AlignHorizontalLeft"))
const ProcurementIcon = React.lazy(()=> import("@mui/icons-material/ReceiptLong"))

const IconObject = {
  Dashboard : DashboardIcon,
  Civil : CivilIcon,
  Liaison : LiaisonIcon,
  BD : BDIcon,
  Admin : AdminIcon,
  Common : CivilIcon,
  Legal : LegalIcon,
  Training : TrainingIcon,
  Accounts : AccountIcon,
  Report : ReportIcon,
  Master : menuIcon,
  Report : ReportIcon,
  Procurement : ProcurementIcon,

}

const returnIcon = (key) =>{
  if(IconObject.hasOwnProperty(key)){
    return IconObject[key]
  }else{
    return IconObject["Common"]
  }
}





const _reportDate = dateFormat(new Date(), "yyyy-MM-DD")
const SidebarMenu = [
  {
    path : '/',
    label : 'Dashboard',
    Icon : React.lazy(()=> import("@mui/icons-material/Home")),
    newTab : false,
    children : [
      {
        path : `/dashboard/civil`,
        label : 'Civil',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/sales`,
        //path : `/`,
        label : 'Sales',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/crm`,
        label : 'CRM',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/marketing`,
        label : 'Marketing',
        newTab : true,
        children : []
      },
      {
        path : `/dashboard/procurment`,
        label : 'Procurment',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/civil',
    label : 'Civil',
    Icon : React.lazy(()=> import("@mui/icons-material/Details")),
    children : [
      {
        path : `/civil/resource-machine-planning`,
        label : 'Machine Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-nmr-planning`,
        label : 'Manpower Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-machine-planning-details`,
        label : 'Machine Planning Details',
        newTab : true,
        children : []
      },
  
      {
        path : `/civil/resource-nmr-planning-details`,
        label : 'Manpower Planning Details',
        newTab : true,
        children : []
      },

      {
        path : `/civil/resource-planning`,
        label : 'Resource Planning',
        newTab : true,
        children : []
      },

      {
        path : `/civil/budget`,
        label : 'Project Budget',
        newTab : true,
        children : []
      },
      {
        path : `/civil/progress`,
        label : 'Project Progress',
        newTab : true,
        children : []
      },
    ]
  },
  {
    path : '/Reports',
    label : 'Liaison',
    Icon : React.lazy(()=> import("@mui/icons-material/Assessment")),
    children : [

      {
        path :  `/liaison-reports/reportDate=${_reportDate}`,
        label : 'Liaison Meeting',
        newTab : true,
        children : []
      },

      {
        path : `/nextHearing/reportDate=${_reportDate}`,
        label : 'Next Hearing',
        newTab : true,
        children : []
      },

      {
        path :  `/courtCase/reportDate=${_reportDate}`,
        label : 'Not updated Court Case',
        newTab : true,
        children : []
      },

      {
        path :  `/liaison_kns_report/reportDate=${_reportDate}`,
        label : 'Liaison Kns Report',
        newTab : true,
        children : []
      },
      {
        path :  `/liaison_shc_report/reportDate=${_reportDate}`,
        label : 'Liaison SHC Report',
        newTab : true,
        children : []
      },
      
      // {
      //   path :  `/liaison-fallowups-reports/reportDate=${_reportDate}`,
      //   label : 'Pending Liaison',
      //   newTab : true,
      //   children : []
      // },
      
      {
        path :  `/liaison_all_report/reportDate=${_reportDate}`,
        label : 'Liaison All Report',
        newTab : true,
        children : []
      },
      // {
      //   path :  `/liaison_own_report/reportDate=${_reportDate}`,
      //   label : 'Liaison Own Report',
      //   newTab : true,
      //   children : []
      // },
    ]
  },
  {
    path : '/',
    label : 'BD',
    Icon : React.lazy(()=> import("@mui/icons-material/LocationCity")),
    newTab : false,
    children : [
      {
        path : `/milestone`,
        label : 'Project Milestone',
        newTab : false,
        children : []
      },

      {
        path : `/business-development/plan`,
        label : 'Project Plan',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/task-plan`,
        label : 'Project Task Plan',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/approval-pending`,
        label : 'Project Task Approval',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/survey-no-pending`,
        label : 'Survey No. Task Approval',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/liason-process-plan`,
        label : 'Liaison Process Plan',
        newTab : false,
        children : []
      },
      // {
      //   path : `/business-development/liason-task-plan`,
      //   label : 'Liaison Task Plan',
      //   newTab : false,
      //   children : []
      // },
      {
        path : `/business-development/liason-documentation`,
        label : 'Liaison Documentation',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/proposed`,
        label : 'Proposed Project',
        newTab : false,
        children : []
      },

      {
        path : `/business-development/project-file`,
        label : 'Project Sy No',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/up-coming`,
        label : 'UpComing Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/on-going`,
        label : 'OnGoing Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/completed`,
        label : 'Completed Project',
        newTab : false,
        children : []
      },
      {
        path : `/business-development/dropped`,
        label : 'Dropped Project',
        newTab : false,
        children : []
      },
    ]
  },
  {
    path : '/authorization',
    label : 'Authorization',
    Icon : React.lazy(()=> import("@mui/icons-material/LocationCity")),
    newTab : false,
    children : []
  }
]

// const menu = JSON.parse(localStorage.getItem('menu'))



const CollpseComponent = ({ item, setSidebar, isSidebar, openParentMenu, setOpenParentMenu, openSubMenu, setOpenSubMenu }) => {

  const handleMenuClick = (menuId) => {
    setOpenParentMenu(prevState => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
    if (!isSidebar) {
      setSidebar(true);
    }
  };

  const handleSubMenuClick = (subMenuId) => {
    setOpenSubMenu(prevState => ({
      ...prevState,
      [subMenuId]: !prevState[subMenuId]
    }));
    if (!isSidebar) {
      setSidebar(true);
    }
  };

  const handleNavLink = (route) => {
    if (!isSidebar) {
      setSidebar(true);
    }
  };

  const ReactIcon = returnIcon(item.menu_name);




  const subTextRefs = React.useRef({});

  React.useEffect(() => {
    const subtextElements = document.querySelectorAll(".subtext, .subtext_direct_link");

    subtextElements.forEach((element) => {
      if (isEllipsisActive(element)) {
        const menuId = element.getAttribute("data-menu-id");
        subTextRefs.current[menuId] = element;
        addTooltip(element);
      }
    });

  });

  function isEllipsisActive(element) {
    return element.offsetWidth < element.scrollWidth;
  }


  function addTooltip(element) {

    // element.setAttribute("title", element.textContent.trim());
    element.setAttribute("data-tooltip-content", element.textContent.trim());
  }


  



  return (
    <>
      <ListItemButton key={item.menu_id} onClick={() => handleMenuClick(item.menu_id)} className='d-flex justify-content-between align-items-center menuItemDivMain'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
          <React.Suspense fallback={<div>..</div>}>
              <ReactIcon />
            </React.Suspense>
            <div className="navbar-text">{item.menu_name} </div>
            {/* <div className={openParentMenu[item.menu_id] ? "navbar-text-bold" : "navbar-text"}>{item.menu_name} </div> */}
          </div>
        </div>
        <div>{openParentMenu[item.menu_id] ? <ExpandLess /> : <ExpandMore />}</div>
      </ListItemButton>
      {(item.menu_item.length > 0) && item.menu_item.map(sub => {
        return  <Collapse in={openParentMenu[item.menu_id]} timeout="auto" unmountOnExit >
         <List component="div" disablePadding key={sub.menu_id}>
            <ListItemButton onClick={() => handleSubMenuClick(sub.menu_id)} className='d-flex justify-content-between align-items-center menuItemDivCollapse'>
              <div className='d-flex justify-content-between sub-item-collapse'>
                <div className='d-flex'>
                <div className={openSubMenu[sub.menu_id] ? "sub-navbar-text-bold" : "sub-navbar-text"}>{sub.menu_name} </div>
                </div>
              </div>
              <div>{openSubMenu[sub.menu_id] ? <RemoveIcon sx={{width:'20px', height:'20px'}}/> : <AddIcon sx={{width:'20px', height:'20px'}}/>}</div>
            </ListItemButton>
            {sub.menu_link.map(subItem => (
            
            <Collapse in={openSubMenu[subItem.menu_id]} timeout="auto" unmountOnExit className='sub-item-collapse-tree' >
              <List component="div" disablePadding>
                  <NavLink key={subItem.menu_id} to={subItem.routing_path} target={subItem.newTab ? "_blank" : ""}>
                    {({ isActive, isPending, isTransitioning }) => (
                      <span className={isActive ? "activeMenu" : ""}>
                        <ListItemButton sx={{ pl: 0 }}  onClick={() => handleNavLink(subItem.routing_path)} className={isActive ? "activeMenu" : "menuItemDiv"}>
                          <div className='horizontal-line'></div>
                          {/* <div className="subtext" ><span style={{fontSize:'4px'}}>&#9898; &nbsp; &nbsp; &nbsp; &nbsp;</span>{subItem.functionality_name}</div> */}
                          {/* <div className="subtext"  data-menu-id={subItem.id}><span style={{ fontSize: '4px' }}>&#9898; &nbsp; &nbsp; &nbsp; &nbsp;</span>{subItem.functionality_name}</div> */}
                          <div className="subtext" data-menu-id={subItem.id} data-tooltip-id="my-tooltip"><span style={{fontSize:'4px'}}>&#9898; &nbsp; &nbsp; &nbsp; &nbsp;</span>{subItem.functionality_name}</div>
                        </ListItemButton>
                      </span>
                    )}
                  </NavLink>
                
              </List>
            </Collapse>))}

          </List> 
    
    
      </Collapse>
      })}

      {(item.menu_link?.length > 0) && item.menu_link.map(subItemLink => (
              <Collapse in={openParentMenu[item.menu_id]} timeout="auto" unmountOnExit className='sub-item-collapse-link'>
              <List component="div" disablePadding >
                  <NavLink key={subItemLink.id} to={subItemLink.routing_path} target={subItemLink.newTab ? "_blank" : ""}>
                    {({ isActive, isPending, isTransitioning }) => (
                      <span className={isActive ? "activeMenu" : ""}>
                        <ListItemButton sx={{ pl: 0 }}  onClick={() => handleNavLink(subItemLink.routing_path)} className={isActive ? "activeMenu" : "menuItemDiv"}>
                          <div className='horizontal-line'></div>
                          {/* <div className="subtext_direct_link">{subItemLink.functionality_name}</div> */}
                          <div className="subtext_direct_link" data-menu-id={subItemLink.id} data-tooltip-id="my-tooltip">{subItemLink.functionality_name}</div>

                        </ListItemButton>
                      </span>
                    )}
                  </NavLink>
                
              </List>
             </Collapse>
      ))}

    </>
  );
};

const drawerWidth = 210;

const openedMixin = (theme) => ({
    width: drawerWidth,
    top:"auto",
    //background : "#fff !important",
    background:"#1d213e !important",
    color:"#fff !important",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    top:"auto",
    // background : "#fff !important",
    background:"#1d213e !important",
    color:"#fff !important",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
  });
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      height : "90vh",
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


  const userLocal = getItem('enuseloc');
  const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;
  
  
  function Navbar(props) {

    const [openParentMenu, setOpenParentMenu] = useState({});
    const [openSubMenu, setOpenSubMenu] = useState({});
    
    const dispatch = useDispatch();
    
    const getUserMenuItemList = useSelector((state) => state.user.getUserMenuItemList);
    // console.log("getUserMenuItemList",getUserMenuItemList)
    const notificationList = useSelector((state) => state.bd.notificationList);
    // console.log("notificationList",notificationList)

  const [menu, setMenu] = React.useState([])


  React.useEffect(() => {
    const payload = {
      userID: LoginuserId,
    };
    dispatch(getUserMenuItem_action(payload));

    return () => {
      setMenu([])
    };
  
  }, []);


  React.useEffect(() => {
  
    if(getUserMenuItemList && getUserMenuItemList.length > 0){
      setMenu(getUserMenuItemList);
    }
  }, [getUserMenuItemList])
  

    const [isSidebar, setSidebar] = useState(true);
    const theme = useTheme();

    const ontoggleClick = () =>{
        setSidebar(!isSidebar);
        setOpenParentMenu({});
    }

    const handleDrawerClose = () => {
        setSidebar(!isSidebar)
        setOpenParentMenu({});        
      };
    // console.log('vvvmenu', menu);
    // menu.map((_menu, index) => (
    //   console.log("nnnnn", _menu)
    // ))
    return (
        <div className='navbar'>
            <div className='header'><ResponsiveAppBar ontoggleClick={ontoggleClick} isSidebar={isSidebar} handleDrawerClose={handleDrawerClose}/></div>
            <div className='main-body'>
                <div className={"sidebar"}> 
                    <Drawer variant="permanent" open={isSidebar}>
                        <List>
                        {menu.map((_menu, index) => (
                          <React.Fragment key={index}>
                          {
                            <CollpseComponent item={_menu} setSidebar={setSidebar} isSidebar = {isSidebar} openParentMenu={openParentMenu} setOpenParentMenu={setOpenParentMenu} openSubMenu={openSubMenu} setOpenSubMenu={setOpenSubMenu} /> 
                          }
                          </React.Fragment>
                        ))}

                        
                        </List>
                        <Divider />
                        {/* <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                            </ListItem>
                        ))}
                        </List> */}
                    </Drawer>
                </div>
                <div className='content'>
                    <div>
                    {/* <Employee  type="list" /> */}
                    {/* <MainRouter /> */}
                    {props.children}
                    {/* {true && <div id="full-loader" style={{display:'flex', cursor:'not-allowed'}} class="full-loader">
              <div class="full-spinner"></div>
          </div>} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;