import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import Notification from '../../Pages/Notifications';
import Badge from '@mui/material/Badge';
import { DocumentUrl } from "../../utils/ApiService";
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyWebNotification_action
} from '../../Redux/BD/action';
import { styled } from '@mui/material/styles';

import { BaseUrl } from '../../utils/ApiService';
import defaultImageUrl from "../../Icons/default-image-icon.jpg";
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const notifications = ['Notification 1 Notification 1 Notification 1 Notification 1 Notification 1 Notification 1', 'Notification 2', 'Notification 3', 'Notification 4'];
const ResponsiveAppBar = (props) => {
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNoti, setAnchorElNoti] = React.useState(null);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const notificationList = useSelector((state) => state.bd.notificationList);
  // console.log("notificationList in AppBar",notificationList)


  const [BaseUrlLabel, setBaseUrlLabel] = React.useState('');
  React.useEffect(() => {
    
    if(BaseUrl.includes('/dev/')){
      setBaseUrlLabel('DEV')
    }
    else if(BaseUrl.includes('/uat/')){
      setBaseUrlLabel('UAT')
    }
    else if(BaseUrl.includes('/test/')){
      setBaseUrlLabel('TEST')
    }else{
      setBaseUrlLabel('')
    }

    return () => {
      setBaseUrlLabel('')
    }
  }, [BaseUrl])
  


  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNotiMenu = (event) => {
    setAnchorElNoti(event.currentTarget);
  };
  
  const user = JSON.parse(localStorage.getItem("user"));
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };
  
  const handleUserMenu = (type) =>{
    // console.log("test",type)
    if(type == "Logout"){
      sessionStorage.clear()
      localStorage.clear()
      navigate('/')
      window.location.reload()
    }else if(type == "Profile"){
      navigate('/profile');
    }
  }

  const dispatch = useDispatch();

  React.useEffect(()=>{
      dispatch(getMyWebNotification_action())
  }, [])


  return (
    <AppBar position="static" sx={{backgroundColor : "#fff"}}>
      <Container maxWidth="xl" sx={{padding: "0px 12px !important"}}>
        <Toolbar disableGutters style={{minHeight : "50px", height: '50px'}}>
        <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
          <MenuIcon className='header_menu text-black-70' onClick={props.handleDrawerClose} />
          </IconButton>
          <Typography className='logo_text'>
            <img src={require("../../Icons/Logo.png")} height={30} /> &nbsp;KNS 
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box className="navbar-rightside-items">
            
            <Tooltip title="Notifications">
            <StyledBadge badgeContent={notificationList ? notificationList?.totalCount : 0} color="secondary">
              <IconButton onClick={() => setShowDrawer(true)} sx={{ p: "0px 5px" }}>
                <NotificationsIcon />
              </IconButton>
            </StyledBadge>
            </Tooltip>
            <Typography>{user?.user_name}</Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <AccountCircleIcon /> */}
                <img className="profilePic" src={DocumentUrl+user?.user_image || defaultImageUrl} alt={'img'}  onError={(e) => {
    e.target.src = defaultImageUrl;
  }}/>
              </IconButton>
            </Tooltip>
            <Typography>
            {
            BaseUrlLabel !== '' ? 
              <span style={{color: 'green' , fontWeight:'bolder' ,display: 'inline-flex', alignItems: 'center'}}>{BaseUrlLabel} &nbsp; <div className="blinking-green"></div></span> 
              : ''
            }
            </Typography>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={()=> {
                  handleUserMenu(setting)
                  handleCloseUserMenu();
                  }}>
                  <Typography>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {showDrawer && <Drawer
      anchor={"right"}
      variant="temporary"
      open={showDrawer}
      onClose={() => setShowDrawer(false)}

      >
        <Notification onClose={setShowDrawer}/>
      </Drawer>}
    </AppBar>
  );
};
export default ResponsiveAppBar;