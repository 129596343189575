import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { User_reducer } from "./User";
import { Report_reducer } from "./Reports";
import { CourtCase_reducer } from "./CourtCase";
import { Civil_reducer } from "./Civil";
import BD_reducer from "./BD/reducer";
import accounts_reducer from "./Accounts/reducer";
import HR_reducer from "./HR/reducer";
import Procurement_reducer from "./Procurement/reducer";
import Sales_reducer from "./Sales/reducer";
import CRM_reducer from "./CRM/reducer";

const All_reducers = combineReducers({
    user: User_reducer,
    reports: Report_reducer,
    courtCase: CourtCase_reducer,
    civil: Civil_reducer,
    bd: BD_reducer,
    accounts: accounts_reducer,
    hr: HR_reducer,
    procurement: Procurement_reducer,
    sales: Sales_reducer,
    crm: CRM_reducer,
});

const store = createStore(
    All_reducers,
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : (f) => f
    )
);

export default store;
