import {
  GET_PROFITABLE_REPORTS,
  GET_LIASONPROCESSOWNER,
  GET_PROJECT_NAMES,
  GET_PENDING_TASK,
  GET_TASK_DELAYS,
  GET_PROFITABLE_REPORT_COUNT,
  GET_LIAISON_PROJECT_NAMES,
  GET_DASHBOARD_DATA,
  GET_USER_DASHBOARD_DATA,
  GET_USER_DASHBOARD_TASK,
  GET_CIVIL_PROCESS_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,
  SET_FUNNEL_ITEM_ID,
  GET_DASHBOARD_STOCK_ZONE_DATA,
  GET_DASHBOARD_STOCK_PROJECT_DATA,
  GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,
  GET_DASHBOARD_STOCK_AGING_ZONE_DATA,
  GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,
  GET_DASHBOARD_STOCK_AGING_FILE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,
  GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_DATA_DEPARTMENT,
  GET_DASHBOARD_DEPARTMENT,
  GET_LIAISON_PROCESS_PLAN_REPORT,
  GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,
  GET_MY_DASHBOARD_DATA_FILTER_USER,
  GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,
  GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,
  GET_DASHBOARD_CRM_SITE_OUTSTANDING,
  GET_DASHBOARD_CRM_PROJECT_TARGET,
  GET_DASHBOEAR_CRM_SITE_TARGET,
  GET_DASHBOARD_CRM_PROJECT_RECEIVED,
  GET_DASHBOARD_CRM_SITE_RECEIVED,
  GET_DASHBOARD_CRM_PROJECT_REALIZED,
  GET_DAHSBOARD_CRM_SITE_REALIZED,
  GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED,
  GET_DAHSBOARD_CRM_SITE_NOT_REALIZED,
  GET_DASHBOARD_DATA_PRE_SALES,
  GET_DASHBOARD_DATA_MARKETING_FUNNEL,
  GET_GRN_ON_TIME_REPORT,
  GET_GRN_ON_TIME_REPORT_EXPORT,
  GET_VENDOR_LEAD_TIME_REPORT,
  GET_VENDOR_LEAD_TIME_REPORT_EXPORT,
  GET_STOCK_PROJECT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT,
  GET_INDENT_PURCHASE_ORDER_KPI_REPORT,
  GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT,
  GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER,
  GET_CIVIL_RESOURCE_REPORT,
  GET_ON_TIME_DELIVERY_KPI,
  GET_ON_TIME_DELIVERY_KPI_EXPORT,
  GET_ON_TIME_DELIVERY_KPI_REPORT,
  GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT,
  GET_PROCUREMENT_KPI_REPORT,
  GET_SALES_CRM_REPORT,
  GET_SALES_CRM_REPORT_EXPORT,
  GET_CRM_PROJECTS,
  GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL,
  GET_SCALE_TYPE,
  GET_CRM_SITE,
  GET_CRM_SITE_DIMENSION,
  GET_KPI_SALES_REPORT,
  GET_KPI_SALES_REPORT_EXPORT,
  GET_KPI_SALES_REPORT_EXECUTIVE_FILTER,
  GET_KPI_SALES_REPORT_DETAILS,
  GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER,
  GET_KPI_SALES_REPORT_DETAILS_EXPORT,
  GET_KPI_PRE_SALES_REPORT,
  GET_KPI_PRE_SALES_REPORT_EXPORT,
  GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER,
  GET_KPI_SALES_PRE_REPORT_DETAILS,
  GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER,
  GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT,
  GET_KPI_SALES_CRM_REPORT,
  GET_KPI_SALES_CRM_REPORT_EXPORT,
  GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER,
  GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER,
  GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER,
  GET_KPI_SALES_REPORT2,
  GET_KPI_SALES_REPORT2_EXPORT,
  GET_KPI_SALES_SUMMARY_REPORT,
  GET_KPI_SALES_SUMMARY_REPORT_EXPORT,
  GET_KPI_SALES_SUMMARY_REPORT_DETAILS,
  GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT,
  GET_KPI_TELECALLING_REPORT,
  GET_KPI_TELECALLING_REPORT_EXPORT,
  GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
  GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
  GET_CAMPAIGN_REPORT,
  GET_CAMPAIGN_REPORT_EXPORT,
  GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT,
  GET_CAMPAIGN_PROJECT_REPORT_EXPORT,
  GET_CAMPAIGN_PROJECT_REPORT,
  GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT,
  GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT,
  GET_CAMPAIGN_ENQUIRY_REPORT,
  GET_CAMPAIGN_BOOKING_REPORT_EXPORT,
  GET_CAMPAIGN_BOOKING_REPORT,
  GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT,
  GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
  GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT,
  GET_CAMPAIGN_SITE_VISIT_REPORT,
  GET_FINANCIAL_YEAR,
  GET_FINANCIAL_YEAR_SCALE_TYPE,
  GET_FINANCIAL_YEAR_SCALE_VALUE,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT,
  GET_PRE_SALES_SITE_VISIT_EXPORT,
  GET_PRE_SALES_SITE_VISIT,
  GET_KPITELECALLING_FILTER_USER,
  GET_KPI_SALES_SUMMARY_FILTER_USER,
  GET_SALES_REPORT,
  GET_SALES_REPORT_EXPORT,
  GET_SALES_REPORT_SITE_VISIT_EXPORT,
  GET_SALES_REPORT_SITE_VISIT,
  GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT,
  GET_SALES_REPORT_PROSPECTIVE_CLIENT,
  GET_SALES_REPORT_FOLLOW_UP_EXPORT,
  GET_SALES_REPORT_FOLLOW_UP,
  GET_SALES_REPORT_BOOKING_EXPORT,
  GET_SALES_REPORT_BOOKING,
  GET_SALES_REPORT_CANCELLATION_EXPORT,
  GET_SALES_REPORT_CANCELLATION,
  GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT,
  GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
  GET_MONTH_WEEK,
  GET_SALES_REPORT_CLIENT_MEETING_EXPORT,
  GET_SALES_REPORT_CLIENT_MEETING,
  GET_SALES_REPORT_PLANNED_CP_MEETING_EXPORT,
  GET_SALES_REPORT_PLANNED_CP_MEETING,
  GET_SALES_REPORT_TEAM_EXPORT,
  GET_SALES_REPORT_TEAM,
  GET_SALES_REPORT_FILTER_TEAM,
  GET_SALES_REPORT_FILTER_USER,
  GET_SALES_REPORT_PLANNED_FOLLOW_UP,
  GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT,
  GET_SALES_REPORT_PLANNED_SITE_VISIT,
  GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT,
  GET_SALES_REPORT_COMPLETED_CP_MEETING,
  GET_SALES_REPORT_COMPLETED_CP_MEETING_EXPORT,
  GET_SALES_REPORT_PLANNED_CLIENT_MEETING_EXPORT,
  GET_SALES_REPORT_PLANNED_CLIENT_MEETING,
  GET_SALES_REPORT_COMPLETED_CLIENT_MEETING_EXPORT,
  GET_SALES_REPORT_COMPLETED_CLIENT_MEETING,
  GET_SALES_REPORT_ENQUIRY_FOLLOW_UP,
  GET_SALES_REPORT_ENQUIRY_FOLLOW_UP_EXPORT,
  GET_SALES_CHANNEL_PARTNER_REPORT,
  GET_SALES_CHANNEL_PARTNER_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT,
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT,
  GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT,
  GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT,
  GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT,
  GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_FILTER_PROJECT,
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_PROJECT,
  GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_FILTER_PROJECT,
  GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_FILTER_PROJECT,
  GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_FILTER_PROJECT,
  GET_SALES_SOURCE_REPORT,
  GET_SALES_SOURCE_REPORT_EXPORT,
  GET_SALES_SOURCE_PROJECT_REPORT,
  GET_SALES_SOURCE_PROJECT_REPORT_EXPORT,
  GET_SALES_SOURCE_ENQUIRY_REPORT_EXPORT,
  GET_SALES_SOURCE_ENQUIRY_REPORT,
  GET_SALES_SOURCE_SITE_VISIT_REPORT_EXPORT,
  GET_SALES_SOURCE_SITE_VISIT_REPORT,
  GET_SALES_SOURCE_BOOKING_REPORT_EXPORT,
  GET_SALES_SOURCE_BOOKING_REPORT,
  GET_SALES_SOURCE_CANCELLATION_REPORT_EXPORT,
  GET_SALES_SOURCE_CANCELLATION_REPORT,
  GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_PROJECT,
  GET_SALES_SOURCE_PROJECT_REPORT_FILTER_PROJECT,
  GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_PROJECT,
  GET_SALES_SOURCE_BOOKING_REPORT_FILTER_PROJECT,
  GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_PROJECT,
  GET_ENQUIRY_SOURCE_CATEGORY,
  GET_ENQUIRY_SOURCE_FILTER,
  GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_CHANNEL_PARTNER,
  GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT_EXPORT,
  GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT,
  GET_SALES_CHANNEL_PARTNER_REPORT_FILTER_SALES_EXECUTIVE,
  GET_PRE_SALES_REPORT,
  GET_PRE_SALES_REPORT_FILTER_EXECUTIVE,
  GET_PRE_SALES_REPORT_FILTER_SOURCE_CATEGORY,
  GET_PRE_SALES_REPORT_FILTER_SOURCE,
  GET_PRE_SALES_REPORT_FILTER_PROJECT,
  GET_PRE_SALES_REPORT_EXPORT,
  GET_PRE_SALES_ENQUIRY_REPORT,
  GET_PRE_SALES_PROJECT_REPORT,
  GET_PRE_SALES_PROJECT_REPORT_EXPORT,
  GET_PRE_SALES_ENQUIRY_REPORT_EXPORT,
  GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT,
  GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT_EXPORT,
  GET_SALES_REPORT_CANCELLATION_PREVIOUS_EXPORT,
  GET_SALES_REPORT_CANCELLATION_PREVIOUS,
  GET_SALES_REPORT_BOOKING_GROSS,
  GET_SALES_REPORT_BOOKING_GROSS_EXPORT,
  GET_PENDING_DASHBOARD_DATA,
  GET_PENDING_ACCOUNTABLE_DASHBOARD_DATA,
  GET_DASHBOARD_DEPARTMENT_DATA,
  GET_PENDING_RESPONSIBLE_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_EXPORT,
  GET_DASHBOARD_DATA_DOCUMENTATION_PLAN,
  GET_DASHBOARD_DATA_DOCUMENTATION_PLAN_EXPORT,
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY,
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_EXPORT,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_EXPORT,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_EXPORT,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_EXPORT,
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_EXPORT,
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING,
  GET_DASHBOARD_DATA_COURT_CASE_EXPORT,
  GET_DASHBOARD_DATA_COURT_CASE,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_EXPORT,
  GET_DASHBOARD_DATA_TODO_EXPORT,
  GET_DASHBOARD_DATA_TODO,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROJECT,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROCESS,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_CATEGORY,
  GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_PROJECT,
  GET_DASHBOARD_DATA_ENQUIRY,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_PROJECT,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_PROJECT,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_PROJECT,
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_PROJECT,
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_PROJECT,
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_COURT_CASE_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_PROJECT,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_CATEGORY,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_TASK,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_TODO_FILTER_PROJECT,
  GET_DASHBOARD_DATA_TODO_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_TODO_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_ACCOUNTABLE,
  GET_SALES_REPORT_BOOKING_FILTER_STM,
  GET_SALES_REPORT_SITE_VISIT_FILTER_STM,
  GET_SALES_REPORT_FOLLOW_UP_FILTER_STM,
  GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_SOURCE,
  GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_SOURCE,
  GET_SALES_SOURCE_BOOKING_REPORT_FILTER_SOURCE,
  GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_SOURCE,
  GET_DASHBOARD_DATA_PROJECT_SURVEY,
  GET_DASHBOARD_DATA_PROJECT_SURVEY_EXPORT,
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_PROJECT,
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_TASK,
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_PROJECT_LEGAL,
  GET_DASHBOARD_DATA_PROJECT_LEGAL_EXPORT,
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_PROJECT,
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_TASK,
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_ACCOUNTABLE,
  GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_RESPONSIBLE,
  GET_DASHBOARD_DATA_ENQUIRY_EXPORT,
  GET_DASHBOARD_DATA_ENQUIRY_SOURCE,
  GET_DASHBOARD_DATA_ENQUIRY_PROJECT,
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED,
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_EXPORT,
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PROJECT,
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_SOURCE,
  GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PRE_SALES,
  GET_DASHBOARD_DATA_SITE_VISIT,
  GET_DASHBOARD_DATA_SITE_VISIT_EXPORT,
  GET_DASHBOARD_DATA_SITE_VISIT_PROJECT,
  GET_DASHBOARD_DATA_SITE_VISIT_SOURCE,
  GET_DASHBOARD_DATA_SITE_VISIT_STM,
  GET_DASHBOARD_DATA_SITE_VISIT_PRE_SALES,
  GET_DASHBOARD_DATA_SALES_BOOKING,
  GET_DASHBOARD_DATA_SALES_BOOKING_EXPORT,
  GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_PROJECT,
  GET_DASHBOARD_DATA_SALES_SOURCE,
  GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_BOOKEDBY,
  GET_DASHBOARD_DATA_CANCELLATION,
  GET_DASHBOARD_DATA_CANCELLATION_EXPORT,
  GET_DASHBOARD_DATA_CANCELLATION_PROJECT,
  GET_DASHBOARD_DATA_CANCELLATION_SOURCE,
  GET_DASHBOARD_DATA_CANCELLATION_STM,
  GET_DASHBOARD_DATA_COLLECTION,
  GET_DASHBOARD_DATA_COLLECTION_EXPORT,
  GET_DASHBOARD_DATA_COLLECTION_PROJECT,
  GET_DASHBOARD_DATA_COLLECTION_USER,
  GET_DASHBOARD_DATA_AGREEMENT,
  GET_DASHBOARD_DATA_AGREEMENT_EXPORT,
  GET_DASHBOARD_DATA_AGREEMENT_PROJECT,
  GET_DASHBOARD_DATA_AGREEMENT_USER,
  GET_DASHBOARD_DATA_REGISTRATION,
  GET_DASHBOARD_DATA_REGISTRATION_EXPORT,
  GET_DASHBOARD_DATA_REGISTRATION_PROJECT,
  GET_DASHBOARD_DATA_REGISTRATION_USER,
} from "./types";

const initialState = {
  profitableReports: [],
  profitableReportCount: 0,
  LiasonProcessOwners: [],
  getProjectNames: [],
  getTaskDelays: [],
  getPendingTasks: [],
  getLisionProjectNames: [],
  DashboardData: [],
  myDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  myDashboardTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  civilProcessProgressData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getDashboardAcquiredButPlanApprovalPendingZoneData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardAcquiredButPlanApprovalPendingProjectData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardAcquiredButPlanApprovalPendingFileData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedZoneData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedProjectData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedFileData: {
    error: false,
    message: "",
    data: [],
  },
  // getFunnelID:{
  //     data:""
  // }
  getBDDashboardDataDepartment: {
    error: false,
    message: "",
    data: [],
  },
  getBDDashboardDepartment: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessPlanReport: {
    error: false,
    message: "",
    data: {
      data: [{}],
      dataTotal: [{}],
    },
    totalCount: 0,
  },
  liaisonProcessPlanReportDetail: {
    error: false,
    message: "",
    data: [],
  },
  getMyDashboardDataFilterUser: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmProjectOutstanding: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmProjectOutstandingExport: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmSiteOutstanding: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectTarget: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteTarget: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectReceived: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteReceived: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectNotRealized: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteNotRealized: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  DashboardDataPreSales: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataMarketingFunnel: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //   nikhil
  getGRNOnTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGRNOnTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorLeadTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorLeadTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getMaterialQuantityRejectionKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialQuantityRejectionKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getMaterialQuantityRejectionKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialQuantityRejectionKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentPurchaseOrderKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentPurchaseOrderKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataRegistrationTurnaroundTime: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReportFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProcurmentKpiReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesCrmReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesCrmReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmProjects: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceReportNonMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getScaleType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSite: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSiteDimension: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getKpiSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKpiSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetailProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportDetails: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportDetailsExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportSourceFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportSalesExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getKPISalesReport2: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReport2Export: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportLeadAssignedDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportLeadAssignedDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReportFilterCampaign: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignEnquiryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignEnquiryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingCancelledReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingCancelledReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignSiteVisitReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignSiteVisitReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYear: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYearScaleType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYearScaleValue: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportSiteVisitFilterStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getSalesReportFollowUpFilterStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getSalesReportBookingFilterStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFollowUp: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFollowUpExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportProspectiveClient: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportProspectiveClientExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBooking: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBookingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportHotProspectiveClient: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportHotProspectiveClientExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMonthWeek: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedCpMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedCpMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportClientMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportClientMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportTeam: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportTeamExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFilterTeam: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedFollowUp: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedFollowUpExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCompletedCpMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCompletedCpMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedClientMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedClientMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCompletedClientMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCompletedClientMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportEnquiryFollowUp: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportEnquiryFollowUpExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //cp sales report
  getSalesChannelPartnerReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerProjectReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerProjectReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerEnquiryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerEnquiryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerSiteVisitReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerSiteVisitReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerBookingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerBookingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerCancellationReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerCancellationReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerProjectReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerEnquiryReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerSiteVisitReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerBookingReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerCancellationReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //source sales report
  getSalesSourceReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceProjectReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceProjectReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceEnquiryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getSalesSourceEnquiryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceEnquiryReportFilterSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceSiteVisitReportFilterSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceBookingReportFilterSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceCancellationReportFilterSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceSiteVisitReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceSiteVisitReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceBookingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceBookingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceCancellationReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceCancellationReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceProjectReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceEnquiryReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceSiteVisitReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceBookingReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesSourceCancellationReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEnquirySourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEnquirySourceFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerProjectReportFilterChannelPartner: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerOnBoardReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerOnBoardReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesChannelPartnerReportFilterSalesExecutive: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesReportFilterExecutive: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesReportFilterSourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesEnquiryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesProjectReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesProjectReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesEnquiryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesEnquiryOverdueReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPreSalesEnquiryOverdueReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellationPrevious: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellationPreviousExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBookingGross: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBookingGrossExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPendingDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPendingTaskDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPendingAccountableDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDepartmentData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPendingResponsibleDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Liasion Process
  getDashboardDataLiaisonProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonProcessPlanExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonProcessPlanFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonProcessPlanFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonProcessPlanFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonProcessPlanFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Liasion Documentation
  getDashboardDataDocumentationPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataDocumentationPlanExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonDocumentationFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonDocumentationFilterCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonDocumentationFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLiaisonDocumentationFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Apf Query
  getDashboardDataApfTaskPlanQuery: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataApfTaskPlanQueryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataApfTaskPlanQueryFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataApfTaskPlanQueryFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataApfTaskPlanQueryFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Loan Query
  getDashboardDataLoanProcessPlanQuery: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLoanProcessPlanQueryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLoanProcessPlanQueryFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLoanProcessPlanQueryFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLoanProcessPlanQueryFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataLoanProcessPlanQueryFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Legal task
  getDashboardDataBdLegalTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Boundary Dispute
  getDashboardDataBdLegalTaskDispute: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskDisputeExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskDisputeFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskDisputeFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataBdLegalTaskDisputeFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //To be Acquired
  getDashboardDataProjectFileAcquisitionPending: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectFileAcquisitionPendingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectFileAcquisitionPendingFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectFileAcquisitionPendingFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectFileAcquisitionPendingFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Court Case
  getDashboardDataCourtCase: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataCourtCaseExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataCourtCaseFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataCourtCaseFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataCourtCaseFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Project Task
  getDashboardDataProjectTaskPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanFilterCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectTaskPlanFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //To do
  getDashboardDataToDo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataToDoExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataToDoFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataToDoFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataToDoFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Survey
  getDashboardDataProjectSurvey: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectSurveyExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectSurveyFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectSurveyFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectSurveyFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectSurveyFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  //Project Legal
  getDashboardDataProjectLegal: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectLegalExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectLegalFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectLegalFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectLegalFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataProjectLegalFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Marketing
  //Enquiry Generated
  getDashboardDataEnquiry: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquirySource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Enquiry Qualificated
  getDashboardDataEnquiryQualified: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryQualifiedExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryQualifiedProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryQualifiedSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataEnquiryQualifiedPreSales: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Pre-Sales
  //Site Visit
  getDashboardDataSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSiteVisitProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSiteVisitSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSiteVisitStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSiteVisitSourcePreSales: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Sales
  //Sales
  getDashboardDataSalesBooking: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSalesBookingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSalesBookingFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSalesSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataSalesBookingFilterBookedBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Cancellation
  getDashboardCancellation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCancellationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCancellationProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCancellationSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCancellationStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //CRM
  //Collection
  getDashboardCollection: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCollectionExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCollectionProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCollectionUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Agreement
  getDashboardAgreement: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardAgreementExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardAgreementProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardAgreementUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //Registration
  getDashboardRegistration: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardRegistrationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardRegistrationProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardRegistrationUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};

const Report_reducer = (state = initialState, { type, payload }) => {
  // console.log(type, "payload", payload)
  switch (type) {
    case GET_PROFITABLE_REPORTS:
      return Object.assign({}, state, { profitableReports: payload });
    case GET_PROFITABLE_REPORT_COUNT:
      return Object.assign({}, state, { profitableReportCount: payload });
    case GET_LIASONPROCESSOWNER:
      return Object.assign({}, state, { LiasonProcessOwners: payload });
    case GET_PROJECT_NAMES:
      return Object.assign({}, state, { getProjectNames: payload });
    case GET_LIAISON_PROJECT_NAMES:
      return Object.assign({}, state, { getLisionProjectNames: payload });
    case GET_TASK_DELAYS:
      return Object.assign({}, state, { getTaskDelays: payload });
    case GET_PENDING_TASK:
      return Object.assign({}, state, { getPendingTasks: payload });
    case GET_DASHBOARD_DATA:
      return Object.assign({}, state, { DashboardData: payload });
    case GET_USER_DASHBOARD_DATA:
      return Object.assign({}, state, { myDashboardData: payload });
    case GET_USER_DASHBOARD_TASK:
      return Object.assign({}, state, { myDashboardTask: payload });
    case GET_CIVIL_PROCESS_DATA:
      return Object.assign({}, state, { civilProcessProgressData: payload });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingZoneData: payload,
      });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingProjectData: payload,
      });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingFileData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedZoneData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedProjectData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedFileData: payload,
      });
    case SET_FUNNEL_ITEM_ID:
      return Object.assign({}, state, { getFunnelID: payload });
    case GET_DASHBOARD_STOCK_ZONE_DATA:
      return Object.assign({}, state, { getDashboardStockZoneData: payload });
    case GET_DASHBOARD_STOCK_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardStockProjectData: payload,
      });
    case GET_DASHBOARD_STOCK_PROJECT_SITE_DATA:
      return Object.assign({}, state, {
        getDashboardStockProjectSiteData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingZoneData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingProjectData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingFileData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredZoneData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredProjectData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredFileData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalZoneData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalProjectData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalFileData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardLandAcquisitionData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardPlanApprovalData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardPlanApprovedTargetActualData: payload,
      });
    case GET_DASHBOARD_DATA_DEPARTMENT:
      return Object.assign({}, state, {
        getBDDashboardDataDepartment: payload,
      });
    case GET_DASHBOARD_DEPARTMENT:
      return Object.assign({}, state, { getBDDashboardDepartment: payload });
    case GET_LIAISON_PROCESS_PLAN_REPORT:
      return Object.assign({}, state, { getLiaisonProcessPlanReport: payload });
    case GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL:
      return Object.assign({}, state, {
        liaisonProcessPlanReportDetail: payload,
      });
    case GET_MY_DASHBOARD_DATA_FILTER_USER:
      return Object.assign({}, state, {
        getMyDashboardDataFilterUser: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING:
      return Object.assign({}, state, {
        getDashboardCrmProjectOutstanding: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT:
      return Object.assign({}, state, {
        getDashboardCrmProjectOutstandingExport: payload,
      });
    case GET_DASHBOARD_CRM_SITE_OUTSTANDING:
      return Object.assign({}, state, {
        getDashboardCrmSiteOutstanding: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_TARGET:
      return Object.assign({}, state, {
        getDashboardCrmProjectTarget: payload,
      });
    case GET_DASHBOEAR_CRM_SITE_TARGET:
      return Object.assign({}, state, { getDashboardCrmSiteTarget: payload });
    case GET_DASHBOARD_CRM_PROJECT_RECEIVED:
      return Object.assign({}, state, {
        getDashboardCrmProjectReceived: payload,
      });
    case GET_DASHBOARD_CRM_SITE_RECEIVED:
      return Object.assign({}, state, { getDashboardCrmSiteReceived: payload });
    case GET_DASHBOARD_CRM_PROJECT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmProjectRealized: payload,
      });
    case GET_DAHSBOARD_CRM_SITE_REALIZED:
      return Object.assign({}, state, { getDashboardCrmSiteRealized: payload });
    case GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmProjectNotRealized: payload,
      });
    case GET_DAHSBOARD_CRM_SITE_NOT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmSiteNotRealized: payload,
      });
    case GET_DASHBOARD_DATA_PRE_SALES:
      return Object.assign({}, state, { DashboardDataPreSales: payload });
    case GET_DASHBOARD_DATA_MARKETING_FUNNEL:
      return Object.assign({}, state, {
        getDashboardDataMarketingFunnel: payload,
      });
    // nikhil
    case GET_GRN_ON_TIME_REPORT:
      return Object.assign({}, state, { getGRNOnTimeKPIReport: payload });

    case GET_GRN_ON_TIME_REPORT_EXPORT:
      return Object.assign({}, state, { getGRNOnTimeKPIReportExport: payload });

    case GET_VENDOR_LEAD_TIME_REPORT:
      return Object.assign({}, state, { getVendorLeadTimeKPIReport: payload });

    case GET_VENDOR_LEAD_TIME_REPORT_EXPORT:
      return Object.assign({}, state, {
        getVendorLeadTimeKPIReportExport: payload,
      });

    case GET_STOCK_PROJECT:
      return Object.assign({}, state, { getStockProject: payload });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI:
      return Object.assign({}, state, { getPurchaseInvoiceOnTimeKPI: payload });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIExport: payload,
      });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIReport: payload,
      });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIReportExport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPI: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIExport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIReport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIReportExport: payload,
      });
    case GET_INDENT_PURCHASE_ORDER_KPI_REPORT:
      return Object.assign({}, state, {
        getIndentPurchaseOrderKPIReport: payload,
      });

    case GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getIndentPurchaseOrderKPIReportExport: payload,
      });
    case GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME:
      return Object.assign({}, state, {
        getDashboardDataRegistrationTurnaroundTime: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReport: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReportFilterProject: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReportFilterUser: payload,
      });
    case GET_CIVIL_RESOURCE_REPORT:
      return Object.assign({}, state, { getCivilResourceReport: payload });
    case GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL:
      return Object.assign({}, state, {
        getCivilResourceReportNonMaterial: payload,
      });
    case GET_ON_TIME_DELIVERY_KPI:
      return Object.assign({}, state, { getOnTimeDeliveryKPI: payload });
    case GET_ON_TIME_DELIVERY_KPI_EXPORT:
      return Object.assign({}, state, { getOnTimeDeliveryKPIExport: payload });
    case GET_ON_TIME_DELIVERY_KPI_REPORT:
      return Object.assign({}, state, { getOnTimeDeliveryKPIReport: payload });
    case GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getOnTimeDeliveryKPIReportExport: payload,
      });
    case GET_PROCUREMENT_KPI_REPORT:
      return Object.assign({}, state, { getProcurmentKpiReport: payload });
    case GET_SALES_CRM_REPORT:
      return Object.assign({}, state, { getSalesCrmReport: payload });
    case GET_SALES_CRM_REPORT_EXPORT:
      return Object.assign({}, state, { getSalesCrmReportExport: payload });
    case GET_CRM_PROJECTS:
      return Object.assign({}, state, { getCrmProjects: payload });
    case GET_SCALE_TYPE:
      return Object.assign({}, state, { getScaleType: payload });
    case GET_CRM_SITE:
      return Object.assign({}, state, { getCrmSite: payload });
    case GET_CRM_SITE_DIMENSION:
      return Object.assign({}, state, { getCrmSiteDimension: payload });
    case GET_KPI_SALES_REPORT:
      return Object.assign({}, state, { getKpiSalesReport: payload });
    case GET_KPI_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getKpiSalesReportExport: payload });

    case GET_KPI_SALES_REPORT_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPISalesReportExecutiveFilter: payload,
      });

    case GET_KPI_SALES_REPORT_DETAILS:
      return Object.assign({}, state, { getKPISalesReportDetail: payload });

    case GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPISalesReportDetailProjectFilter: payload,
      });

    case GET_KPI_SALES_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportDetailExport: payload,
      });

    case GET_KPI_PRE_SALES_REPORT:
      return Object.assign({}, state, { getKPIPreSalesReport: payload });

    case GET_KPI_PRE_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getKPIPreSalesReportExport: payload });
    case GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPIPreSalesReportExecutiveFilter: payload,
      });
    case GET_KPI_SALES_PRE_REPORT_DETAILS:
      return Object.assign({}, state, { getKPIPreSalesReportDetails: payload });
    case GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPIPreSalesReportProjectFilter: payload,
      });

    case GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesReportDetailsExport: payload,
      });

    case GET_KPI_SALES_CRM_REPORT:
      return Object.assign({}, state, { getKPISalesCrmReport: payload });
    case GET_KPI_SALES_CRM_REPORT_EXPORT:
      return Object.assign({}, state, { getKPISalesCrmReportExport: payload });
    case GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportProjectFilter: payload,
      });
    case GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportSourceFilter: payload,
      });

    case GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportSalesExecutiveFilter: payload,
      });
    case GET_KPI_SALES_REPORT2:
      return Object.assign({}, state, { getKPISalesReport2: payload });
    case GET_KPI_SALES_REPORT2_EXPORT:
      return Object.assign({}, state, { getKPISalesReport2Export: payload });
    case GET_KPI_SALES_SUMMARY_REPORT:
      return Object.assign({}, state, { getKPISalesSummaryReport: payload });
    case GET_KPI_SALES_SUMMARY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getKPISalesSummaryReportExport: payload,
      });
    case GET_KPI_SALES_SUMMARY_REPORT_DETAILS:
      return Object.assign({}, state, {
        getKPISalesSummaryReportDetail: payload,
      });
    case GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesSummaryReportDetailExport: payload,
      });
    case GET_KPI_TELECALLING_REPORT:
      return Object.assign({}, state, {
        getKPITelecallingReport: payload,
      });
    case GET_KPI_TELECALLING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getKPITelecallingReportExport: payload,
      });

    case GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL:
      return Object.assign({}, state, {
        getKPIPreSalesReportLeadAssignedDetail: payload,
      });
    case GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesReportLeadAssignedDetailExport: payload,
      });

    case GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL:
      return Object.assign({}, state, {
        getKPISalesReportSiteVisitDetail: payload,
      });

    case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS:
      return Object.assign({}, state, {
        getKPISalesReportCrmBookingDetail: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportCrmBookingDetailExport: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS:
      return Object.assign({}, state, {
        getKPISalesReportCrmCancellationDetail: payload,
      });
    case GET_CAMPAIGN_REPORT:
      return Object.assign({}, state, {
        getCampaignReport: payload,
      });
    case GET_CAMPAIGN_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignReportExport: payload,
      });
    case GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN:
      return Object.assign({}, state, {
        getCampaignReportFilterCampaign: payload,
      });
    case GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportSiteVisitDetailExport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT:
      return Object.assign({}, state, {
        getCampaignProjectReport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignProjectReportExport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCampaignProjectReportFilterProject: payload,
      });
    case GET_CAMPAIGN_ENQUIRY_REPORT:
      return Object.assign({}, state, {
        getCampaignEnquiryReport: payload,
      });
    case GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignEnquiryReportExport: payload,
      });
    case GET_CAMPAIGN_BOOKING_REPORT:
      return Object.assign({}, state, {
        getCampaignBookingReport: payload,
      });
    case GET_CAMPAIGN_BOOKING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignBookingReportExport: payload,
      });
    case GET_CAMPAIGN_BOOKING_CANCELLED_REPORT:
      return Object.assign({}, state, {
        getCampaignBookingCancelledReport: payload,
      });
    case GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignBookingCancelledReportExport: payload,
      });
    case GET_CAMPAIGN_SITE_VISIT_REPORT:
      return Object.assign({}, state, {
        getCampaignSiteVisitReport: payload,
      });
    case GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignSiteVisitReportExport: payload,
      });
    case GET_FINANCIAL_YEAR:
      return Object.assign({}, state, {
        getFinancialYear: payload,
      });
    case GET_FINANCIAL_YEAR_SCALE_TYPE:
      return Object.assign({}, state, {
        getFinancialYearScaleType: payload,
      });
    case GET_FINANCIAL_YEAR_SCALE_VALUE:
      return Object.assign({}, state, {
        getFinancialYearScaleValue: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportCrmCancellationDetailExport: payload,
      });

    case GET_PRE_SALES_SITE_VISIT:
      return Object.assign({}, state, {
        getKPIPreSalesSiteVisit: payload,
      });

    case GET_PRE_SALES_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesSiteVisitExport: payload,
      });

    case GET_KPITELECALLING_FILTER_USER:
      return Object.assign({}, state, { getKPITelecallingFilterUser: payload });

    case GET_KPI_SALES_SUMMARY_FILTER_USER:
      return Object.assign({}, state, {
        getKPISalesSummaryFilterUser: payload,
      });
    // case DISPLAY_MESSAGE:
    //     return Object.assign({}, state, { messageInfo: payload });

    // case DISPLAY_ERROR:
    //     return Object.assign({}, state, { error: payload.error });

    // case SUCCESS_STATUS:
    //     return Object.assign({}, state, { status: payload.status });

    // case OTP_SENT_SUCCESS:
    //     return Object.assign({}, state, { otp_sent: true });

    // case OTP_VALID_FAILS:
    //     return Object.assign({}, state, { valid_otp: false });

    // case OTP_VALID_SUCCESS:
    //         return Object.assign({}, state, { valid_otp: true });
    case GET_SALES_REPORT:
      return Object.assign({}, state, { getSalesReport: payload });
    case GET_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getSalesReportExport: payload });
    case GET_SALES_REPORT_SITE_VISIT:
      return Object.assign({}, state, { getSalesReportSiteVisit: payload });
    case GET_SALES_REPORT_SITE_VISIT_FILTER_STM:
      return Object.assign({}, state, {
        getSalesReportSiteVisitFilterStm: payload,
      });
    case GET_SALES_REPORT_BOOKING_FILTER_STM:
      return Object.assign({}, state, {
        getSalesReportBookingFilterStm: payload,
      });
    case GET_SALES_REPORT_FOLLOW_UP_FILTER_STM:
      return Object.assign({}, state, {
        getSalesReportFollowUpFilterStm: payload,
      });
    case GET_SALES_REPORT_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportSiteVisitExport: payload,
      });
    case GET_SALES_REPORT_FOLLOW_UP:
      return Object.assign({}, state, { getSalesReportFollowUp: payload });
    case GET_SALES_REPORT_FOLLOW_UP_EXPORT:
      return Object.assign({}, state, {
        getSalesReportFollowUpExport: payload,
      });
    case GET_SALES_REPORT_PROSPECTIVE_CLIENT:
      return Object.assign({}, state, {
        getSalesReportProspectiveClient: payload,
      });
    case GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportProspectiveClientExport: payload,
      });
    case GET_SALES_REPORT_BOOKING:
      return Object.assign({}, state, { getSalesReportBooking: payload });
    case GET_SALES_REPORT_BOOKING_EXPORT:
      return Object.assign({}, state, { getSalesReportBookingExport: payload });
    case GET_SALES_REPORT_CANCELLATION:
      return Object.assign({}, state, { getSalesReportCancellation: payload });
    case GET_SALES_REPORT_CANCELLATION_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCancellationExport: payload,
      });
    case GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT:
      return Object.assign({}, state, {
        getSalesReportHotProspectiveClient: payload,
      });
    case GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportHotProspectiveClientExport: payload,
      });
    case GET_MONTH_WEEK:
      return Object.assign({}, state, { getMonthWeek: payload });
    case GET_SALES_REPORT_PLANNED_CP_MEETING:
      return Object.assign({}, state, {
        getSalesReportPlannedCpMeeting: payload,
      });
    case GET_SALES_REPORT_PLANNED_CP_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportPlannedCpMeetingExport: payload,
      });
    case GET_SALES_REPORT_CLIENT_MEETING:
      return Object.assign({}, state, { getSalesReportClientMeeting: payload });
    case GET_SALES_REPORT_CLIENT_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportClientMeetingExport: payload,
      });
    case GET_SALES_REPORT_TEAM:
      return Object.assign({}, state, {
        getSalesReportTeam: payload,
      });
    case GET_SALES_REPORT_TEAM_EXPORT:
      return Object.assign({}, state, {
        getSalesReportTeamExport: payload,
      });
    case GET_SALES_REPORT_FILTER_TEAM:
      return Object.assign({}, state, {
        getSalesReportFilterTeam: payload,
      });
    case GET_SALES_REPORT_FILTER_USER:
      return Object.assign({}, state, {
        getSalesReportFilterUser: payload,
      });
    case GET_SALES_REPORT_PLANNED_FOLLOW_UP:
      return Object.assign({}, state, {
        getSalesReportPlannedFollowUp: payload,
      });
    case GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT:
      return Object.assign({}, state, {
        getSalesReportPlannedFollowUpExport: payload,
      });
    case GET_SALES_REPORT_PLANNED_SITE_VISIT:
      return Object.assign({}, state, {
        getSalesReportPlannedSiteVisit: payload,
      });
    case GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportPlannedSiteVisitExport: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getKPISalesReportCrmBookingDetailFilterProject: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getKPISalesReportCrmCancellationDetailFilterProject: payload,
      });
    case GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getKPISalesReportSiteVisitDetailFilterProject: payload,
      });
    case GET_SALES_REPORT_COMPLETED_CP_MEETING:
      return Object.assign({}, state, {
        getSalesReportCompletedCpMeeting: payload,
      });

    case GET_SALES_REPORT_COMPLETED_CP_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCompletedCpMeetingExport: payload,
      });
    case GET_SALES_REPORT_PLANNED_CLIENT_MEETING:
      return Object.assign({}, state, {
        getSalesReportPlannedClientMeeting: payload,
      });
    case GET_SALES_REPORT_PLANNED_CLIENT_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportPlannedClientMeetingExport: payload,
      });
    case GET_SALES_REPORT_COMPLETED_CLIENT_MEETING:
      return Object.assign({}, state, {
        getSalesReportCompletedClientMeeting: payload,
      });
    case GET_SALES_REPORT_COMPLETED_CLIENT_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCompletedClientMeetingExport: payload,
      });
    case GET_SALES_REPORT_ENQUIRY_FOLLOW_UP:
      return Object.assign({}, state, {
        getSalesReportEnquiryFollowUp: payload,
      });
    case GET_SALES_REPORT_ENQUIRY_FOLLOW_UP_EXPORT:
      return Object.assign({}, state, {
        getSalesReportEnquiryFollowUpExport: payload,
      });
    //cp sales report
    case GET_SALES_CHANNEL_PARTNER_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerProjectReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerProjectReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerEnquiryReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerEnquiryReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerSiteVisitReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerSiteVisitReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerBookingReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerBookingReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerCancellationReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerCancellationReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesChannelPartnerProjectReportFilterProject: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_ENQUIRY_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesChannelPartnerEnquiryReportFilterProject: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_SITE_VISIT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesChannelPartnerSiteVisitReportFilterProject: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_BOOKING_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesChannelPartnerBookingReportFilterProject: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_CANCELLATION_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesChannelPartnerCancellationReportFilterProject: payload,
      });
    //source sales report
    case GET_SALES_SOURCE_REPORT:
      return Object.assign({}, state, {
        getSalesSourceReport: payload,
      });
    case GET_SALES_SOURCE_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceReportExport: payload,
      });
    case GET_SALES_SOURCE_PROJECT_REPORT:
      return Object.assign({}, state, {
        getSalesSourceProjectReport: payload,
      });
    case GET_SALES_SOURCE_PROJECT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceProjectReportExport: payload,
      });
    case GET_SALES_SOURCE_ENQUIRY_REPORT:
      return Object.assign({}, state, {
        getSalesSourceEnquiryReport: payload,
      });
    case GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_SOURCE:
      return Object.assign({}, state, {
        getSalesSourceEnquiryReportFilterSource: payload,
      });
    case GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_SOURCE:
      return Object.assign({}, state, {
        getSalesSourceSiteVisitReportFilterSource: payload,
      });
    case GET_SALES_SOURCE_BOOKING_REPORT_FILTER_SOURCE:
      return Object.assign({}, state, {
        getSalesSourceBookingReportFilterSource: payload,
      });
    case GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_SOURCE:
      return Object.assign({}, state, {
        getSalesSourceCancellationReportFilterSource: payload,
      });
    case GET_SALES_SOURCE_ENQUIRY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceEnquiryReportExport: payload,
      });
    case GET_SALES_SOURCE_SITE_VISIT_REPORT:
      return Object.assign({}, state, {
        getSalesSourceSiteVisitReport: payload,
      });
    case GET_SALES_SOURCE_SITE_VISIT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceSiteVisitReportExport: payload,
      });
    case GET_SALES_SOURCE_BOOKING_REPORT:
      return Object.assign({}, state, {
        getSalesSourceBookingReport: payload,
      });
    case GET_SALES_SOURCE_BOOKING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceBookingReportExport: payload,
      });
    case GET_SALES_SOURCE_CANCELLATION_REPORT:
      return Object.assign({}, state, {
        getSalesSourceCancellationReport: payload,
      });
    case GET_SALES_SOURCE_CANCELLATION_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesSourceCancellationReportExport: payload,
      });
    case GET_SALES_SOURCE_PROJECT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesSourceProjectReportFilterProject: payload,
      });
    case GET_SALES_SOURCE_ENQUIRY_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesSourceEnquiryReportFilterProject: payload,
      });
    case GET_SALES_SOURCE_SITE_VISIT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesSourceSiteVisitReportFilterProject: payload,
      });
    case GET_SALES_SOURCE_BOOKING_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesSourceBookingReportFilterProject: payload,
      });
    case GET_SALES_SOURCE_CANCELLATION_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getSalesSourceCancellationReportFilterProject: payload,
      });
    case GET_ENQUIRY_SOURCE_CATEGORY:
      return Object.assign({}, state, {
        getEnquirySourceCategory: payload,
      });
    case GET_ENQUIRY_SOURCE_FILTER:
      return Object.assign({}, state, {
        getEnquirySourceFilter: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_PROJECT_REPORT_FILTER_CHANNEL_PARTNER:
      return Object.assign({}, state, {
        getSalesChannelPartnerProjectReportFilterChannelPartner: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerOnBoardReport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_ON_BOARD_REPORT_EXPORT:
      return Object.assign({}, state, {
        getSalesChannelPartnerOnBoardReportExport: payload,
      });
    case GET_SALES_CHANNEL_PARTNER_REPORT_FILTER_SALES_EXECUTIVE:
      return Object.assign({}, state, {
        getSalesChannelPartnerReportFilterSalesExecutive: payload,
      });
    case GET_PRE_SALES_REPORT:
      return Object.assign({}, state, {
        getPreSalesReport: payload,
      });
    case GET_PRE_SALES_REPORT_FILTER_EXECUTIVE:
      return Object.assign({}, state, {
        getPreSalesReportFilterExecutive: payload,
      });
    case GET_PRE_SALES_REPORT_FILTER_SOURCE_CATEGORY:
      return Object.assign({}, state, {
        getPreSalesReportFilterSourceCategory: payload,
      });
    case GET_PRE_SALES_REPORT_FILTER_SOURCE:
      return Object.assign({}, state, {
        getPreSalesReportFilterSource: payload,
      });

    case GET_PRE_SALES_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getPreSalesReportFilterProject: payload,
      });
    case GET_PRE_SALES_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPreSalesReportExport: payload,
      });
    case GET_PRE_SALES_ENQUIRY_REPORT:
      return Object.assign({}, state, {
        getPreSalesEnquiryReport: payload,
      });

    case GET_PRE_SALES_PROJECT_REPORT:
      return Object.assign({}, state, {
        getPreSalesProjectReport: payload,
      });
    case GET_PRE_SALES_PROJECT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPreSalesProjectReportExport: payload,
      });
    case GET_PRE_SALES_ENQUIRY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPreSalesEnquiryReportExport: payload,
      });
    case GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT:
      return Object.assign({}, state, {
        getPreSalesEnquiryOverdueReport: payload,
      });

    case GET_PRE_SALES_ENQUIRY_OVER_DUE_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPreSalesEnquiryOverdueReportExport: payload,
      });
    case GET_SALES_REPORT_CANCELLATION_PREVIOUS:
      return Object.assign({}, state, {
        getSalesReportCancellationPrevious: payload,
      });
    case GET_SALES_REPORT_CANCELLATION_PREVIOUS_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCancellationPreviousExport: payload,
      });
    case GET_SALES_REPORT_BOOKING_GROSS:
      return Object.assign({}, state, {
        getSalesReportBookingGross: payload,
      });
    case GET_SALES_REPORT_BOOKING_GROSS_EXPORT:
      return Object.assign({}, state, {
        getSalesReportBookingGrossExport: payload,
      });
    //My Dashboard
    case GET_PENDING_DASHBOARD_DATA:
      return Object.assign({}, state, {
        getPendingDashboardData: payload,
      });
    case GET_PENDING_ACCOUNTABLE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        getPendingAccountableDashboardData: payload,
      });
    case GET_DASHBOARD_DEPARTMENT_DATA:
      return Object.assign({}, state, {
        getDashboardDepartmentData: payload,
      });
    case GET_PENDING_RESPONSIBLE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        getPendingResponsibleDashboardData: payload,
      });
    //Liasion Process
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlan: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlanExport: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlanFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_PROCESS:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlanFilterProcess: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlanFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_PROCESS_PLAN_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataLiaisonProcessPlanFilterResponsible: payload,
      });
    //Liasion Documenetation
    case GET_DASHBOARD_DATA_DOCUMENTATION_PLAN:
      return Object.assign({}, state, {
        getDashboardDataDocumentationPlan: payload,
      });
    case GET_DASHBOARD_DATA_DOCUMENTATION_PLAN_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataDocumentationPlanExport: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataLiaisonDocumentationFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_CATEGORY:
      return Object.assign({}, state, {
        getDashboardDataLiaisonDocumentationFilterCategory: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataLiaisonDocumentationFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_LIAISON_DOCUMENTATION_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataLiaisonDocumentationFilterResponsible: payload,
      });
    //APF Query
    case GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY:
      return Object.assign({}, state, {
        getDashboardDataApfTaskPlanQuery: payload,
      });
    case GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataApfTaskPlanQueryExport: payload,
      });
    case GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataApfTaskPlanQueryFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataApfTaskPlanQueryFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataApfTaskPlanQueryFilterResponsible: payload,
      });
    //Loan Query
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQuery: payload,
      });
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQueryExport: payload,
      });
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQueryFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQueryFilterProcess: payload,
      });
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQueryFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataLoanProcessPlanQueryFilterResponsible: payload,
      });
    //Legal Task
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTask: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskExport: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskFilterResponsible: payload,
      });
    //Boundary Dispute
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskDispute: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskDisputeExport: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskDisputeFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskDisputeFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_BD_LEGAL_TASK_DISPUTE_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataBdLegalTaskDisputeFilterResponsible: payload,
      });
    //To Be Acquired
    case GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING:
      return Object.assign({}, state, {
        getDashboardDataProjectFileAcquisitionPending: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataProjectFileAcquisitionPendingExport: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataProjectFileAcquisitionPendingFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataProjectFileAcquisitionPendingFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_FILE_ACQUISITION_PENDING_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataProjectFileAcquisitionPendingFilterResponsible: payload,
      });
    //Court Case
    case GET_DASHBOARD_DATA_COURT_CASE:
      return Object.assign({}, state, {
        getDashboardDataCourtCase: payload,
      });
    case GET_DASHBOARD_DATA_COURT_CASE_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataCourtCaseExport: payload,
      });
    case GET_DASHBOARD_DATA_COURT_CASE_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataCourtCaseFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_COURT_CASE_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataCourtCaseFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_COURT_CASE_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataCourtCaseFilterResponsible: payload,
      });
    //Project Task
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlan: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlanExport: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlanFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_TASK:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlanFilterTask: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlanFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_TASK_PLAN_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataProjectTaskPlanFilterResponsible: payload,
      });
    //To Do
    case GET_DASHBOARD_DATA_TODO:
      return Object.assign({}, state, {
        getDashboardDataToDo: payload,
      });
    case GET_DASHBOARD_DATA_TODO_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataToDoExport: payload,
      });
    case GET_DASHBOARD_DATA_TODO_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataToDoFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_TODO_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataToDoFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_TODO_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataToDoFilterResponsible: payload,
      });

    //Survey
    case GET_DASHBOARD_DATA_PROJECT_SURVEY:
      return Object.assign({}, state, {
        getDashboardDataProjectSurvey: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_SURVEY_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataProjectSurveyExport: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataProjectSurveyFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_TASK:
      return Object.assign({}, state, {
        getDashboardDataProjectSurveyFilterTask: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataProjectSurveyFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_SURVEY_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataProjectSurveyFilterResponsible: payload,
      });

    //Project Legal
    case GET_DASHBOARD_DATA_PROJECT_LEGAL:
      return Object.assign({}, state, {
        getDashboardDataProjectLegal: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_LEGAL_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataProjectLegalExport: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataProjectLegalFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_TASK:
      return Object.assign({}, state, {
        getDashboardDataProjectLegalFilterTask: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getDashboardDataProjectLegalFilterAccountable: payload,
      });
    case GET_DASHBOARD_DATA_PROJECT_LEGAL_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getDashboardDataProjectLegalFilterResponsible: payload,
      });

    //Makerting
    //Enquiry Generated
    case GET_DASHBOARD_DATA_ENQUIRY:
      return Object.assign({}, state, {
        getDashboardDataEnquiry: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataEnquiryExport: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataEnquiryProject: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_SOURCE:
      return Object.assign({}, state, {
        getDashboardDataEnquirySource: payload,
      });

    //Enquiry Qualificated
    case GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED:
      return Object.assign({}, state, {
        getDashboardDataEnquiry: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataEnquiryExport: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataEnquiryProject: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_SOURCE:
      return Object.assign({}, state, {
        getDashboardDataEnquirySource: payload,
      });
    case GET_DASHBOARD_DATA_ENQUIRY_QUALIFIED_PRE_SALES:
      return Object.assign({}, state, {
        getDashboardDataEnquirySource: payload,
      });

    //Pre-Sales
    //Site Visit
    case GET_DASHBOARD_DATA_SITE_VISIT:
      return Object.assign({}, state, {
        getDashboardDataSiteVisit: payload,
      });
    case GET_DASHBOARD_DATA_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataSiteVisitExport: payload,
      });
    case GET_DASHBOARD_DATA_SITE_VISIT_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataSiteVisitProject: payload,
      });
    case GET_DASHBOARD_DATA_SITE_VISIT_SOURCE:
      return Object.assign({}, state, {
        getDashboardDataSiteVisitSource: payload,
      });
    case GET_DASHBOARD_DATA_SITE_VISIT_STM:
      return Object.assign({}, state, {
        getDashboardDataSiteVisitStm: payload,
      });
    case GET_DASHBOARD_DATA_SITE_VISIT_PRE_SALES:
      return Object.assign({}, state, {
        getDashboardDataSiteVisitSourcePreSales: payload,
      });

    //Sales
    //Sales
    case GET_DASHBOARD_DATA_SALES_BOOKING:
      return Object.assign({}, state, {
        getDashboardDataSalesBooking: payload,
      });
    case GET_DASHBOARD_DATA_SALES_BOOKING_EXPORT:
      return Object.assign({}, state, {
        getDashboardDataSalesBookingExport: payload,
      });
    case GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_PROJECT:
      return Object.assign({}, state, {
        getDashboardDataSalesBookingFilterProject: payload,
      });
    case GET_DASHBOARD_DATA_SALES_SOURCE:
      return Object.assign({}, state, {
        getDashboardDataSalesSource: payload,
      });
    case GET_DASHBOARD_DATA_SALES_BOOKIG_FILTER_BOOKEDBY:
      return Object.assign({}, state, {
        getDashboardDataSalesBookingFilterBookedBy: payload,
      });

    //Cancellation
    case GET_DASHBOARD_DATA_CANCELLATION:
      return Object.assign({}, state, {
        getDashboardCancellation: payload,
      });
    case GET_DASHBOARD_DATA_CANCELLATION_EXPORT:
      return Object.assign({}, state, {
        getDashboardCancellationExport: payload,
      });
    case GET_DASHBOARD_DATA_CANCELLATION_PROJECT:
      return Object.assign({}, state, {
        getDashboardCancellationProject: payload,
      });
    case GET_DASHBOARD_DATA_CANCELLATION_SOURCE:
      return Object.assign({}, state, {
        getDashboardCancellationSource: payload,
      });
    case GET_DASHBOARD_DATA_CANCELLATION_STM:
      return Object.assign({}, state, {
        getDashboardCancellationStm: payload,
      });

    //CRM
    //Collection
    case GET_DASHBOARD_DATA_COLLECTION:
      return Object.assign({}, state, {
        getDashboardCollection: payload,
      });
    case GET_DASHBOARD_DATA_COLLECTION_EXPORT:
      return Object.assign({}, state, {
        getDashboardCollectionExport: payload,
      });
    case GET_DASHBOARD_DATA_COLLECTION_PROJECT:
      return Object.assign({}, state, {
        getDashboardCollectionProject: payload,
      });
    case GET_DASHBOARD_DATA_COLLECTION_USER:
      return Object.assign({}, state, {
        getDashboardCollectionUser: payload,
      });

    //Agreement
    case GET_DASHBOARD_DATA_AGREEMENT:
      return Object.assign({}, state, {
        getDashboardAgreement: payload,
      });
    case GET_DASHBOARD_DATA_AGREEMENT_EXPORT:
      return Object.assign({}, state, {
        getDashboardAgreementExport: payload,
      });
    case GET_DASHBOARD_DATA_AGREEMENT_PROJECT:
      return Object.assign({}, state, {
        getDashboardAgreementProject: payload,
      });
    case GET_DASHBOARD_DATA_AGREEMENT_USER:
      return Object.assign({}, state, {
        getDashboardAgreementUser: payload,
      });

    //Registration
    case GET_DASHBOARD_DATA_REGISTRATION:
      return Object.assign({}, state, {
        getDashboardRegistration: payload,
      });
    case GET_DASHBOARD_DATA_REGISTRATION_EXPORT:
      return Object.assign({}, state, {
        getDashboardRegistrationExport: payload,
      });
    case GET_DASHBOARD_DATA_REGISTRATION_PROJECT:
      return Object.assign({}, state, {
        getDashboardRegistrationProject: payload,
      });
    case GET_DASHBOARD_DATA_REGISTRATION_USER:
      return Object.assign({}, state, {
        getDashboardRegistrationUser: payload,
      });

    default:
      return state;
  }
};

export default Report_reducer;
